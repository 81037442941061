<template>
    <v-container

            fluid
            grid-list-xl>
        <v-layout

                wrap
        >
            <v-flex
                    md8
                    xs12
            >
                <v-card


                >
                    <v-list-item :style="`background-color: ${info.color}`" dark>
                        <v-list-item-content>
                            <v-list-item-title class="headline">Edit Profile</v-list-item-title>
                            <v-list-item-subtitle>Complete/Update your profile</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <p/>
                    <v-form>
                        <v-container py-0>
                            <v-layout wrap>
                                <v-flex
                                        md6
                                        xs12
                                >
                                    <v-color-picker class="ma-2" show-swatches swatches-max-height="150px"
                                                    v-model="info.color"/>

                                </v-flex>
                                <v-flex
                                        md6

                                        xs12
                                >
                                    <div v-if="!image">
                                        <v-file-input
                                                :rules="rules"
                                                @change="onFileChange"
                                                accept="image/png, image/jpeg, image/bmp"
                                                label="Avatar"
                                                placeholder="Pick an avatar"
                                                prepend-icon="mdi-camera"
                                                ref="myFile"
                                                show-size
                                                type="file"
                                                v-model="avatarImage"
                                        />
                                    </div>
                                    <div v-else>
                                        <!--suppress HtmlUnknownTarget -->
                                        <img :src="image" alt="Profile Image" style="width: 50%"/>
                                        <p/>
                                        <v-btn @click="removeImage">Remove image</v-btn>
                                    </div>

                                </v-flex>

                                <v-flex
                                        md6
                                        xs12
                                >
                                    <v-text-field
                                            class="purple-input"
                                            label="User Name"
                                            v-model="info.name"
                                    />
                                </v-flex>
                                <v-flex
                                        md6
                                        xs12
                                >
                                    <v-text-field
                                            class="purple-input"
                                            disabled
                                            label="Email Address"
                                            readonly
                                            v-model="info.email"/>
                                </v-flex>

                                <v-flex xs12><h6 class="caption font-weight-light">
                                    Location auto filled based on your IP
                                </h6></v-flex>
                                <v-flex
                                        md4
                                        xs12>
                                    <v-text-field
                                            class="purple-input"
                                            label="City"
                                            v-model="info.city"
                                    />
                                </v-flex>
                                <v-flex
                                        md4
                                        xs12>
                                    <v-text-field
                                            class="purple-input"
                                            label="State"
                                            v-model="info.state"

                                    />
                                </v-flex>
                                <v-flex
                                        md4
                                        xs12>
                                    <v-text-field
                                            class="purple-input"
                                            label="Country"
                                            v-model="info.country"

                                    />
                                </v-flex>
                                <v-flex

                                        xs12>
                                    <v-checkbox label="Share my location information with everyone"
                                                v-model="info.shareLocation"/>
                                </v-flex>
                                <v-flex
                                        md4
                                        xs12>
                                    <p>Your Gender</p>
                                    <v-radio-group column v-model="info.gender">
                                        <v-radio label="Male" value="Male"/>
                                        <v-radio label="Female" value="Female"/>
                                        <v-radio label="Other" value="Other"/>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex
                                        xs12>
                                    <v-checkbox label="Share my gender information with everyone"
                                                v-model="info.shareGender"/>
                                </v-flex>
                                <v-flex xs12>
                                    <v-textarea
                                            class="purple-input"
                                            label="About Me"
                                            v-model="info.about"
                                    />
                                </v-flex>
                                <v-flex
                                        text-xs-right
                                        xs12
                                >
                                    <v-btn
                                            :loading="processing"
                                            @click="sendProfileDataToServer"
                                            class="mx-0 font-weight-light mb-12"
                                            color="success"
                                    >
                                        Update Profile
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card>
            </v-flex>
            <v-flex
                    md4
                    xs12
            >
                <ProfileComponent :info="info"/>
            </v-flex>
            <div class="text-center ma-2">
                <v-snackbar
                        v-model="snackbar"
                >
                    {{ snackbarText }}
                    <v-btn
                            @click="snackbar = false"
                            color="pink"
                            text
                    >
                        Close
                    </v-btn>
                </v-snackbar>
            </div>
        </v-layout>
    </v-container>
</template>


<!--suppress JSUnresolvedVariable -->
<script>
    import ProfileComponent from "../components/ProfileComponent";
    // import {AxiosInstance as axios} from "axios";
    import {APIService} from "../APIService";

    const apiService = new APIService();
    export default {
        metaInfo: {
            title: 'Update Your Profile',
            titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
                {
                    name: 'description',
                    content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
                }
            ]
        },
        name: "UserProfile",
        components: {ProfileComponent},
        data() {

            return {
                snackbar: false,
                snackbarText: "",
                processing: false,
                profileUpdated: false,
                info: {
                    name: "Alec Thompson",
                    about: "In love with Dental Pockets",
                    city: "Agra",
                    state: "Uttar Pradesh",
                    country: "India",
                    email: "trial@trial.com",
                    shareLocation: true,
                    gender: "",
                    shareGender: true,
                    file: null,
                    avatar: "https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg",
                    color: "#2D8122",
                },
                image: '',
                avatarImage: null,
                rules: [
                    value => !value || value.size < 4000000 || 'Avatar size should be less than 4 MB!',
                ],
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.$store.commit("setShowProgress", true);

                // Code that will run only after the
                // entire view has been rendered

                this.getProfileInfoFromServer();

                // if (localStorage.getItem("profileUpdated") !== null) {
                //     let isProfileUpdated = localStorage.getItem("profileUpdated");
                //     if (isProfileUpdated) {
                //     }
                // }
            });
        },
        methods: {

            getProfileInfoFromServer() {
                this.$store.commit("setShowProgress", true);
                // eslint-disable-next-line no-console
                // console.log(this.info);

                apiService.getProfileInfoFromServer().then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data);

                    if (Object.keys(data.info).length > 0) {
                        // eslint-disable-next-line no-console
                        // console.log(data.info);

                        this.info = data.info;
                        localStorage.setItem("profileUpdated", "yes");

                        this.getLocationInfo();
                    } else {
                        // eslint-disable-next-line no-console
                        // console.log("Not Found");

                        this.getUserInfo();

                    }

                    this.$store.commit("setShowProgress", false);


                });
            },
            sendProfileDataToServer() {
                this.processing = true;
                // eslint-disable-next-line no-console
                // console.log(this.info);

                apiService.sendProfileUpdateToServer(this.info).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data);
                    if (parseInt(data.data.status) === 200) {
                        this.processing = false;
                        this.profileUpdated = true;
                        localStorage.setItem("profileUpdated", "yes");
                        this.snackbarText = "Profile Has been Updated.";
                        this.snackbar = true;
                    } else {
                        localStorage.setItem("profileUpdated", "no");
                        this.profileUpdated = false;
                        this.processing = false;

                        this.snackbarText = "Profile Could not be updated. Please try again later. If problem persists please contact us.";
                        this.snackbar = true;
                    }

                });
            },
            getUserInfo() {
                this.info.name = this.$store.getters.getUserName;
                this.info.email = this.$store.getters.getUserEmail;
                this.info.avatar = this.$store.getters.getPhotoUrl;
            },
            getLocationInfo() {
                let ipdetails = JSON.parse(localStorage.getItem("ipdetails"));
                this.info.city = ipdetails.city;
                this.info.state = ipdetails.region;
                this.info.country = ipdetails.country_name;

            },
            onFileChange() {
                if (this.avatarImage) {
                    let name = this.avatarImage.name;
                    apiService.sendUserAvatar(this.avatarImage, name).then(data => {
                        this.info.avatar = data;
                    });

                    this.createImage(this.avatarImage);
                }
            },
            // onFileChange(e) {
            //     let files = e.target.files || e.dataTransfer.files;
            //     if (!files.length)
            //         return;
            //     this.createImage(files[0]);
            // },
            createImage(file) {
                // let image = new Image();
                let reader = new FileReader();
                let vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            removeImage: function () {
                this.image = '';
                this.avatarImage = null;
            }
        },
        beforeRouteLeave(to, from, next) {
            if (!this.profileUpdated) {
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            } else {
                next();
            }

        }

    }
</script>

